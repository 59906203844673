import React from 'react';
import { Scrollchor } from 'react-scrollchor';

function Nav() {
    return (

        <div id="navid">
            <nav className="navbar fixed-top navbar-expand-lg">

                <a className="navbar-brand" href="home">Manav</a>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Scrollchor to="#timeline" className="navbar-item">
                                <div className="nav-link" href="x">Projects</div>
                            </Scrollchor>
                        </li>
                        <li className="nav-item">
                            <Scrollchor to="#Colab" className="navbar-item">
                                <div className="nav-link" href="./Colab">Colab</div>
                            </Scrollchor>
                        </li>

                    </ul>
                </div>

            </nav>
        </div>

    );

}


export default Nav;
