import React, { useEffect } from "react";
import Nav from './nav';
import Lpage from './landing';

import Exp from './exp/exp';
import Footer from './footer';
import Skill from './skill';
import Research from './research';
import AOS from "aos";
import Colab from "./colab";
import "aos/dist/aos.css";


export default function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <Nav />
      <Lpage />
      <Exp />
      <Research />
      <Skill />
      <Colab />
      <Footer />
    </>
  );
}
