import React from 'react';
import { Carousel } from '3d-react-carousal';

let slides = [
    <div className="skill-card">
        <div className="skill-card-top">
            <h3 className="skill-card-heading">WEB-DEV</h3>
            <div className="sk-rate">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half"></i>
            </div>
        </div>
        <div className="skill-card-bottom">
            <div className="skill-def">
                <h5 className="skill-def-name">HTML/CSS</h5>
                <h5 className="skill-def-name">Bootstrap</h5>
                <h5 className="skill-def-name">REACT</h5>
                <h5 className="skill-def-name">NodeJS</h5>
                <h5 className="skill-def-name">ThreeJS</h5>
                <h5 className="skill-def-name">MongoDB</h5>
                <h5 className="skill-def-name">SQL</h5>
            </div>
        </div>
    </div>,
    <div className="skill-card">
        <div className="skill-card-top">
            <h3 className="skill-card-heading">Programing languages</h3>
            <div className="sk-rate">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
            </div>
        </div>
        <div className="skill-card-bottom">
            <div className="skill-def">
                <h5 className="skill-def-name">JavaScript</h5>
                <h5 className="skill-def-name">Python</h5>
                <h5 className="skill-def-name">C++</h5>
                <h5 className="skill-def-name">JAVA</h5>
            </div>
        </div>
    </div>,
    <div className="skill-card">
        <div className="skill-card-top">
            <h3 className="skill-card-heading">Technologies</h3>
            <div className="sk-rate">
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star-half"></i>
            </div>
        </div>
        <div className="skill-card-bottom">
            <div className="skill-def">
                <h5 className="skill-def-name">MERN Stack</h5>
                <h5 className="skill-def-name">Data Science</h5>
                <h5 className="skill-def-name">App Development</h5>
                <h5 className="skill-def-name">Aws Pipeline</h5>
            </div>
        </div>
    </div>,


];

function Skill(props) {
    return (
        <div className="skill-container">
            <h1 className="sk-heading" data-aos="fade-down">SKILLS</h1>
            <Carousel slides={slides} autoplay={false} />
        </div>
    );

}

export default Skill;