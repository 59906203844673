import React from 'react';
import Typed from "react-typed";
import { Scrollchor } from 'react-scrollchor';



function Lpage() {
    return (
        <div id="landing-page">
            <div className="lp-container row">
                <div className="con1 col-lg-6">
                    <img className="imgmp" src="https://images.unsplash.com/photo-1529236183275-4fdcf2bc987e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80" alt="jxj" width="100%" height="100%" />
                    <h4 className="x2" data-aos="fade-right">
                        <span id="cx1">
                            <Typed
                                loop
                                typeSpeed={100}
                                backSpeed={75}
                                strings={["Developer", "Computer Engineer", "Coder"]}
                                smartBackspace
                                shuffle={false}
                                backDelay={1}
                                fadeOut={false}
                                fadeOutDelay={100}
                                loopCount={0}
                                showCursor
                                cursorChar="|"
                            />
                        </span>
                    </h4>
                </div>
                <div className="con2 col-lg-6">
                    <div className="subcon2" data-aos="fade-left">
                        <h1 className="conh1">CREAVTIVE GUY,</h1>
                        <h1 className="conh1">IN AN ORDINARY  WORLD.</h1>
                        <br />
                        <br />
                        <Scrollchor to="#Colab" className="lp-a">
                            <div className="nav-link" href="./Colab">Let's talk</div>
                        </Scrollchor>
                    </div>
                </div>
            </div>
            <br />
        </div>
    );
}

export default Lpage;