import React from 'react';
import emailjs from 'emailjs-com';

function Colab (){

    
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_1u54uz5', 'template_axfzn1i', e.target, 'user_6qvQecA6EpmbqgtCJIUqD')
      .then((result) => {

          alert('Thank you,   Your responce has been submitted');
          console.log(result.text);

      }, (error) => {
          alert('Error 503');
          console.log(error.text);
      });
  }


    return(

        <section id="Colab">
            <div className="colab-container row">
                <div className="colab-info-con col-lg-5">
                    <h3 className="colab-head">Contact Information</h3>
                    <p className="colab-p">Fill the form and I will contact you as soon as possible.</p>
                    <div className="contact-detail-con row">
                        <i className="far fa-envelope"></i>
                        <p className="contact-detail-p">manavpro77@gmail.com</p>
                    </div>
                    <div className="contact-detail-con row">
                        <i className="fas fa-map-marker-alt"></i>
                        <p className="contact-detail-p">Pune, Maharashtra</p>
                    </div>
                </div>
                <div className="colab-form-con col-lg-7">
                    <div className="contact-form">
                        <form className="input-fields" onSubmit={sendEmail}>
                            <input type="text" className="input" name="name" placeholder="Name"/>
                            <input type="email" className="input" name="user_email" placeholder="Email Address"/>
                            <input type="text" className="input" name="subject" placeholder="Subject"/>
                            <input type="text" className="input" name="message" placeholder="Message"/>
                            <button type="submit" className="btn x1-x" value="Send" placeholder="send">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <br/>
        </section>
        
    );
    
}

export default Colab;
