import React from 'react';
import "./style.css";


function Exp() {
    return (

        <div>

            <section id="timeline">
                <h1 className="exp-heading" data-aos="fade-down">PROJECTS</h1>
                <div className="demo-card-wrapper">
                    {/* <div className="demo-card demo-card--step1" data-aos="fade-down-right">
                        <div className="head">
                            <div className="number-box">
                                <span>01</span>
                            </div>
                            <h2><span className="small"></span> Portfolio Website</h2>
                        </div>
                        <div className="body">
                            <p>The website shows hold in Full stack MERN web-development, the site have blog section which will be consist of MongoDB as database also have various complex section like contact which uses hooks to send info provide in form to my email.</p>
                            <img className="graph" src="./images/pxx.png" alt="Graphic" ></img>
                        </div>
                    </div> */}

                    <div className="demo-card demo-card--step1" data-aos="fade-down-right">
                        <div className="head">
                            <div className="number-box">
                                <span>01</span>
                            </div>
                            <h2><span className="small"></span> MUSIC PLAYER WEB APP</h2>
                        </div>
                        <div className="body">
                            <p>A web based application uses is MERN stack and VueJs along with serverless AWS api for data modules for music and genra suggestion. In future the Api integration with various platforms like Instagram and Spotify web which will increase the reach of the website along with optimised SEO.</p>
                            <a href="https://manav347.github.io/musicPlayer/" target="_blank" rel="noreferrer">
                                <img className="graph" src="./images/player.png" alt="Graphic" style={{ transition: 'transform 0.3s' }} onMouseOver={(e) => e.target.style.transform = 'scale(1.08)'} onMouseOut={(e) => e.target.style.transform = 'scale(1)'}></img>
                            </a>
                        </div>
                    </div>


                    {/* <div className="demo-card demo-card--step2" data-aos="fade-down-left">
                        <div className="head">
                            <div className="number-box">
                                <span>02</span>
                            </div>
                            <h2><span className="small"></span>Demo Farm Website</h2>
                        </div>
                        <div className="body">
                            <p>This website is a demo for a local dairy, as it would improve its online presence, the site was a simple website as to a demo of the actual product in future, this website mainly focus on compatibility will all the form-factor relative to the device.</p>
                            <img className="graph" src="./images/pxx2.png" alt="Graphic"></img>
                        </div>
                    </div> */}

                    <div className="demo-card demo-card--step2" data-aos="fade-down-left">
                        <div className="head">
                            <div className="number-box">
                                <span>02</span>
                            </div>
                            <h2><span className="small"></span>EXPERTVAULT</h2>
                        </div>
                        <div className="body">
                            <p>I was assigned to contribute in front-end as well as build an Ai module that fetches experts and provides the best compatible client and pairs them. Technologies used are MERN stack, and set of AI modules running on Ec2 instance, lamda function and a textract module on AWS.</p>
                            <a href="https://www.expertsvault.in/" target="_blank" rel="noreferrer">
                                <img className="graph" src="./images/Expert.png" alt="Graphic" style={{ transition: 'transform 0.3s' }} onMouseOver={(e) => e.target.style.transform = 'scale(1.08)'} onMouseOut={(e) => e.target.style.transform = 'scale(1)'}></img>
                            </a>
                        </div>
                    </div>


                    <div className="demo-card demo-card--step2" data-aos="fade-down-left">
                        <div className="head">
                            <div className="number-box">
                                <span>03</span>
                            </div>
                            <h2><span className="small"></span>CINESTOX</h2>
                        </div>
                        <div className="body">
                            <p>Client's requirements was to provide portal instance to invest and trade in movie stocks. Technologies used are MERN stack along with NodeJs as a backend, Dynamodb database and react native for app.</p>
                            <a href="https://play.google.com/store/apps/details?id=com.cinestox.app" target="_blank" rel="noreferrer">
                                <img className="graph" src="./images/Cine.png" alt="Graphic" style={{ transition: 'transform 0.3s' }} onMouseOver={(e) => e.target.style.transform = 'scale(1.08)'} onMouseOut={(e) => e.target.style.transform = 'scale(1)'}></img>
                            </a>
                        </div>
                    </div>


                    <div className="demo-card demo-card--step2" data-aos="fade-down-left">
                        <div className="head">
                            <div className="number-box">
                                <span>04</span>
                            </div>
                            <h2><span className="small"></span>SETUGRAM</h2>
                        </div>
                        <div className="body">
                            <p>Setugram is a start-up website composite of e-commerce, an internship portal and also an online course portal.The technologies used are MERN stack along with STRAPI as a backend with serverless architecture running on AWS.</p>
                            <a href="https://play.google.com/store/apps/details?id=com.cinestox.app" target="_blank" rel="noreferrer">
                                <img className="graph" src="./images/Setu.png" alt="Graphic" style={{ transition: 'transform 0.3s' }} onMouseOver={(e) => e.target.style.transform = 'scale(1.08)'} onMouseOut={(e) => e.target.style.transform = 'scale(1)'}></img>
                            </a>
                        </div>
                    </div>

                    <div className="demo-card demo-card--step3" data-aos="fade-right">
                        <div className="head">
                            <div className="number-box">
                                <span>05</span>
                            </div>
                            <h2><span className="small"></span>Email automation</h2>
                        </div>
                        <div className="body">
                            <p>This is a python project which collects the data from the start-up and companies from the specific region targeted and send then can send an email via sendgrid. As this project is to show my creative mind-set.</p>
                            <img className="graph" src="./images/Auto.png" alt="Graphic"></img>
                        </div>
                    </div>


                    {/* 
                    <div className="demo-card demo-card--step4" data-aos="fade-up-left">
                        <div className="head">
                            <div className="number-box">
                                <span>04</span>
                            </div>
                            <h2><span className="small"></span>Hotel Management</h2>
                        </div>
                        <div className="body">
                            <p>This was group project to serve a end one solution for a stay-in Hotel management app. Futher AI will be also integrated to suggest smart space and management.</p>
                            <img className="graph" src="./images/pxx4.png" alt="Graphic"></img>
                        </div>
                    </div>

                    <div className="demo-card demo-card--step5" data-aos="fade-up-right">
                        <div className="head">
                            <div className="number-box">
                                <span>05</span>
                            </div>
                            <h2><span className="small"></span>API protocall</h2>
                        </div>
                        <div className="body">
                            <p>The project is a broad-way to explore the API services of companies. As of now a basic Google maps API is being built to server as a backbone for a NGO's.</p>
                            <img className="graph" src="./images/pxx5.png" alt="Graphic"></img>
                        </div>
                    </div> */}

                </div>
            </section>

        </div>

    );
}

export default Exp;