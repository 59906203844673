import React from "react";

function Footer() {
  return (
    <footer id="Footer" className="FooterComponent">



    <div className="footer-con container">
      <div className="bottom-container">
        <a className="footer-link" href="https://www.linkedin.com/in/manav-patel-b57539191"><i className="fab fa-linkedin-in"></i></a>
        <a className="footer-link" href="https://twitter.com/ManavPa64940097"><i className="fab fa-twitter"></i></a>
        <a className="footer-link" href="https://github.com/manav347"><i className="fab fa-github"></i></a>
      </div>
    </div>

    </footer>
  );
}

export default Footer;
