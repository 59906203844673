import React from 'react';
import { Carousel } from '3d-react-carousal';

let slides = [
    <div className="skill-card2">
        <div className="skill-card-top">
            <h4 className="skill-card-heading">H-SHIELD WOMEN SAFETY WEARABLE DEVICE</h4>
            <div className="sk-rate">
                <h5 className="skill-def-name">| 202221048969 | Issued Sep 9, 2022 </h5>
            </div>
        </div>
        <div className="skill-card-bottom">
            <div className="skill-def2">
                <h7 className="skill-def-name2">Safety H – Shield is a wearable pendant device that is built for women’s safety it helps to extricate themselves from their situation. The device is based on IoT and ML data model with serverless architecture based on cloud computing to act as independent device. Thus enables the device to streamline the emergency data independent of the primary device. Passive live location is also enabled once the emergency module is triggered.(Status : Published)</h7>
            </div>
        </div>
    </div>,
    <div className="skill-card2">
        <div className="skill-card-top">
            <h4 className="skill-card-heading">IMAGE-DEV : AN ADVANCE TEXT TO IMAGE AI MODEL</h4>
            <div className="sk-rate">
                <h5 className="skill-def-name">IEEE Punecon 2022 | Jan 19, 2023</h5>
            </div>
        </div>
        <div className="skill-card-bottom">
            <div className="skill-def2">
                <h7 className="skill-def-name2">Proposed layer model sits between the test pre-processing layer and diffusion base model. Image-dev is suited for conflict category where state of the art model like IMAGEN and DALLE fall behind, but lacks in photo realism. (Status : Published)</h7>
            </div>
        </div>
    </div>,
    <div className="skill-card2">
        <div className="skill-card-top">
            <h4 className="skill-card-heading">VECMA : AN ADVANCE CHESS ENGINE</h4>
            <div className="sk-rate">
                <h5 className="skill-def-name">IEEE Punecon 2022 | Jan 19, 2023</h5>
            </div>
        </div>
        <div className="skill-card-bottom">
            <div className="skill-def2">
                <h7 className="skill-def-name2">Vecma uses Negamax algorithm, along with positional vectors to optimize and increase the efficiency of the previously based engine which uses alpha-beta pruning. Vecma not only beats some of the state-of-the-art chess engines but also is quite efficient only operating at the game tree at level 5. (Status : Published)</h7>
            </div>
        </div>
    </div>


];

function Research(props) {
    return (
        <div className="skill-container">
            <h1 className="sk-heading" data-aos="fade-down">RESEARCH</h1>
            <div className="skill-container2">
                <Carousel slides={slides} autoplay={false} />
            </div>
        </div>
    );

}

export default Research;